<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        Detalles trabajo
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editJob">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre'" label-for="job-name">
                  <b-form-input
                    v-model="job.name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Estado'"
                rules="required"
              >
                <b-form-group :label="'Estado'" label-for="job-status">
                  <v-select
                    v-model="job.status"
                    :filterable="false"
                    :searchable="false"
                    :options="selectStatus"
                    :placeholder="'Estado'"
                  >
                    <template slot="option" slot-scope="option">
                      {{ statusJobsText[option.label] }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ statusJobsText[option.label] }}
                    </template>
                  </v-select>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Departamento'"
                rules="required"
              >
                <b-form-group
                  :label="'Departamento'"
                  label-for="job-department"
                >
                  <v-select
                    v-model="department"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectDepartments"
                    :placeholder="'Departamento'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Categoría'"
                rules="required"
              >
                <b-form-group :label="'Categoría'" label-for="job-category">
                  <v-select
                    v-model="job.category"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectCategories"
                    :placeholder="'Categoría'"
                    :disabled="readOnlyCat"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Obra'"
                rules="required"
              >
                <b-form-group :label="'Obra'" label-for="job-project">
                  <v-select
                    v-model="job.project"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectProjects"
                    :placeholder="'Obra'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <validation-provider
                #default="{ errors }"
                :name="'Fecha de inicio'"
                rules="required"
              >
                <b-form-group
                  :label="'Fecha de inicio'"
                  label-for="job-fechaini"
                >
                  <flat-pickr
                    v-model="date_ini"
                    name="date_ini"
                    class="form-control"
                    :placeholder="'dd/mm/YYYY'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <b-form-group :label="'Fecha de fin'" label-for="job-fechafin">
                <flat-pickr
                  v-model="date_fin"
                  name="date_fin"
                  class="form-control"
                  :placeholder="'dd/mm/YYYY'"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group :label="'Descripción'" label-for="job-description">
                <quill-editor v-model="job.description" />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group :label="'Documentos'" label-for="job-documents">
                <ImageDropzone
                  ref="documents"
                  :files="files"
                  maxFiles="10"
                  v-if="jobPrepare"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ "Enviar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {
  FlatPickrToTimestampWithTime,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import flatPickr from "vue-flatpickr-component";
import { config } from "@/shared/app.config";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    flatPickr,
    ImageDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusJobsVariant: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      required,
      email,
      date_ini: "",
      date_fin: "",
      department: "",
      files: [],
      jobPrepare: false,
    };
  },
  watch: {
    department() {
      this.chargeCategories();
    },
  },
  computed: {
    ...mapGetters({
      job: "jobs/getJob",
      selectStatus: "jobs/getSelectStatus",
      selectProjects: "projects/getSelectProjects",
      selectCategories: "categories/getSelectCategories",
      selectDepartments: "departments/getSelectDepartments",
    }),
    readOnlyCat() {
      if (this.department) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      edit: "jobs/edit",
      getJob: "jobs/getJob",
      getSelectCategories: "categories/getSelectCategories",
      getSelectDepartments: "departments/selectDepartments",
      getSelectStatus: "jobs/getSelectStatus",
      getSelectProjects: "projects/getSelectProjects",
    }),
    handleSubmit() {
      this.$refs.editJob.validate().then((success) => {
        if (success) {
          const { id } = this.job;
          const formData = this.createFormData();
          if (formData) {
            this.edit({ id, task: formData });
          }
        }
      });
    },
    setData() {
      if (this.job.date_ini) {
        this.date_ini = TimestampToFlatPickrWithTime(this.job.date_ini);
      }
      if (this.job.date_fin && this.job.date_fin !== "null") {
        this.date_fin = TimestampToFlatPickrWithTime(this.job.date_fin);
      }
      if (this.job.documents.length > 0) {
        const aux = this;
        this.job.documents.forEach((element, index) => {
          const file = {
            name: `File ${index + 1}`,
            url: element.path,
            urlDelete: `/tasks/delete_doc/${aux.job.id}/${element.id}`,
          };
          this.files.push(file);
        });
      }
      if (this.job.department) {
        this.department = this.job.department;
      }
      this.jobPrepare = true;
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents");
      data.append("date_ini", FlatPickrToTimestampWithTime(this.date_ini));
      if (this.date_fin) {
        data.append("date_fin", FlatPickrToTimestampWithTime(this.date_fin));
      } else {
        data.append("date_fin", null);
      }
      data.append("name", this.job.name);
      data.append("project_id", this.job.project.id);
      data.append("category_id", this.job.category.id);
      data.append("status", this.job.status);
      data.append("description", this.job.description);

      return data;
    },
    chargeCategories() {
      const department = this.department ? this.department.id : "";
      this.getSelectCategories({ department });
    },
  },
  async created() {
    await this.getJob(this.$route.params.id);
    await this.getSelectDepartments({});
    await this.setData();
    await this.getSelectStatus();
    await this.chargeCategories();
    await this.getSelectProjects({});
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",attrs:{"variant":"white","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})],1)],1),_vm._v(" Detalles trabajo ")])]),_c('b-card',[_c('validation-observer',{ref:"editJob"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Nombre',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Nombre',"label-for":"job-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":'Nombre'},model:{value:(_vm.job.name),callback:function ($$v) {_vm.$set(_vm.job, "name", $$v)},expression:"job.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Estado',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Estado',"label-for":"job-status"}},[_c('v-select',{attrs:{"filterable":false,"searchable":false,"options":_vm.selectStatus,"placeholder":'Estado'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusJobsText[option.label])+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(_vm.statusJobsText[option.label])+" ")]}}],null,true),model:{value:(_vm.job.status),callback:function ($$v) {_vm.$set(_vm.job, "status", $$v)},expression:"job.status"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Departamento',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Departamento',"label-for":"job-department"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectDepartments,"placeholder":'Departamento'},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Categoría',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Categoría',"label-for":"job-category"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectCategories,"placeholder":'Categoría',"disabled":_vm.readOnlyCat},model:{value:(_vm.job.category),callback:function ($$v) {_vm.$set(_vm.job, "category", $$v)},expression:"job.category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Obra',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Obra',"label-for":"job-project"}},[_c('v-select',{attrs:{"label":"name","filterable":true,"searchable":true,"options":_vm.selectProjects,"placeholder":'Obra'},model:{value:(_vm.job.project),callback:function ($$v) {_vm.$set(_vm.job, "project", $$v)},expression:"job.project"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'Fecha de inicio',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'Fecha de inicio',"label-for":"job-fechaini"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date_ini","placeholder":'dd/mm/YYYY'},model:{value:(_vm.date_ini),callback:function ($$v) {_vm.date_ini=$$v},expression:"date_ini"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Fecha de fin',"label-for":"job-fechafin"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"date_fin","placeholder":'dd/mm/YYYY'},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":'Descripción',"label-for":"job-description"}},[_c('quill-editor',{model:{value:(_vm.job.description),callback:function ($$v) {_vm.$set(_vm.job, "description", $$v)},expression:"job.description"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":'Documentos',"label-for":"job-documents"}},[(_vm.jobPrepare)?_c('ImageDropzone',{ref:"documents",attrs:{"files":_vm.files,"maxFiles":"10"}}):_vm._e()],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s("Enviar")+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }